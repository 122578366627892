import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: "Uploadcare Cookie Policy",
  description: "",
  header: "Cookie Policy",
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {
      /* Effective Date: November 26, 2021 */
    }
    <h2 {...{
      "id": "what-are-cookies"
    }}><a parentName="h2" {...{
        "href": "#what-are-cookies"
      }}>{`What are Cookies?`}</a></h2>
    <p>{`To make our website and Services work properly, Uploadcare may store cookies
(and other technologies, like pixels and beacons) on your browser or device.`}</p>
    <p>{`Cookie is a small text file that a website saves on your computer or mobile
device when you visit the site. It allows the website to remember your
activities and preferences (such as login, language, font size, and other
display preferences).`}</p>
    <p>{`For more information, please visit
`}<a parentName="p" {...{
        "href": "https://www.allaboutcookies.org/"
      }}>{`AllAboutCookies.org`}</a></p>
    <p>{`Cookies can be "First Party," when they are run by Uploadcare, or "Third Party"
when they are run by a third party when you browse our website. Uploadcare
cannot access third-party cookies, nor can third parties access the data in the
cookies we use on our website.`}</p>
    <p>{`Cookies are stored for different lengths of time on your browser or device.
"Session cookies" disappear from your device when you close your web browser.
“Persistent cookies” will stay on your device until they get manually deleted or
until they expire.`}</p>
    <p>{`Uploadcare website and Services may contain third party pixels that allow
certain third parties to set or read cookies located on your device. This pixel
will help us assess the success of our ads campaigns and website or Services
functionalities. We may use marketing Pixels, such as Facebook and Google
AdWords Remarketing Tag, to measure the effectiveness of our ads, to define
custom audiences for ad targeting, for dynamic ads campaigns, and to analyze the
effectiveness of our website's conversion funnels. Please notice the chart
located below where you can choose to opt-in/out of these technologies.`}</p>
    <p>{`As a general rule, Uploadcare uses cookies to improve and personalize its Sites
and Services and/or measure its audience. Cookies are files saved to your local
storage when browsing on the internet and in particular on our Sites. A cookie
is not used to gather your personal information without your knowledge but
instead to record information on site browsing which can be read directly by
Uploadcare on your subsequent visits.`}</p>
    <h2 {...{
      "id": "how-to-control-cookies"
    }}><a parentName="h2" {...{
        "href": "#how-to-control-cookies"
      }}>{`How to control cookies`}</a></h2>
    <p>{`You can choose to decline acceptance of all non-essential cookies, but your
ability to browse certain pages of our website and Services may be reduced. The
cookies used by Uploadcare are intended to enable or facilitate communication,
to enable the Services requested by users to be supplied, to recognize users
when they re-visit the site, to secure payments which users may make, or other
preferences necessary for the service requested to be supplied and to enable
Uploadcare, internally, to carry out analyses on hit rates and browsing
experience so as to improve the content, to track email open rates, click rates,
and bounce-back rates at individual levels.`}</p>
    <p>{`By default, cookies are not installed automatically if you come from the
European Economic Area, UK or Switzerland (except for those cookies needed to
run the Uploadcare website and Services, and you are informed of their
installation by a clickable banner with a text description). In accordance with
the regulations that apply, Uploadcare will require your authorization before
implanting any other kind of cookie to your local storage. To avoid being
bothered by these routine requests for authorization and to enjoy uninterrupted
browsing, you can configure your device to accept Uploadcare cookies, or we can
remember your refusal or acceptance of certain cookies. By default, browsers
accept all cookies.`}</p>
    <p>{`You can delete all cookies that are already on your device, and you can set most
browsers to prevent them from being placed. If you do this, however, some
services and functionalities may not work on this website.`}</p>
    <p>{`Find out how to manage cookies on popular browsers:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/accounts/answer/61416"
        }}>{`Google Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
        }}>{`Mozilla
Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
        }}>{`Apple Safari`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.microsoft.com/sr-latn-rs/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
        }}>{`Microsoft
Edge`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
        }}>{`Microsoft Internet
Explorer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://help.opera.com/en/latest/web-preferences/#cookies"
        }}>{`Opera`}</a></li>
    </ul>
    <p>{`To find information relating to other browsers, visit the browser developer's
website.`}</p>
    <p>{`To opt-out of being tracked by Google Analytics across all websites, visit
`}<a parentName="p" {...{
        "href": "http://tools.google.com/dlpage/gaoptout"
      }}>{`http://tools.google.com/dlpage/gaoptout`}</a>{`.`}</p>
    <p>{`You can turn off third-party cookies generated by advertisers by going to the
Your Online Choices website by selecting your state/area of residency:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youronlinechoices.eu/"
        }}>{`European Economic Area or Switzerland`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youradchoices.ca/"
        }}>{`Canada`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youradchoices.com/"
        }}>{`USA`}</a></li>
    </ul>
    <p>{`You can also visit the trade body representing these advertising platforms for
more information: `}<a parentName="p" {...{
        "href": "https://www.networkadvertising.org/choices/;"
      }}>{`Network Advertising
Initiative`}</a>{`.`}</p>
    <p>{`When you access third party sites on our Sites, or when you are reading
integration or social media links, cookies can be created by the companies
disseminating these links. These third parties may be able to use cookies in the
context of Uploadcare’s Services (partners or other third parties supplying
content or services available on the Uploadcare site) and are responsible for
the cookies they install, and it is their conditions on cookies which apply. For
more information, you are advised to check the cookie policy directly on these
third-party sites concerning their use of cookies.`}</p>
    <h2 {...{
      "id": "how-do-we-use-cookies"
    }}><a parentName="h2" {...{
        "href": "#how-do-we-use-cookies"
      }}>{`How do we use cookies?`}</a></h2>
    <h3 {...{
      "id": "strictly-necessary-cookies"
    }}><a parentName="h3" {...{
        "href": "#strictly-necessary-cookies"
      }}>{`Strictly Necessary Cookies`}</a></h3>
    <p>{`These cookies are necessary for the website to function and cannot be switched
off in our systems. They are usually only set in response to actions made by
you which amount to a request for services, such as setting your privacy
preferences, logging in or filling in forms. You can set your browser to block
or alert you about these cookies, but some parts of the site will not then
work. These cookies do not store any personally identifiable information.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie Subgroup`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies used`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/AWSALB"
            }}>{`AWSALB`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/AWSALBCORS"
            }}>{`AWSALBCORS`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_dc_gtm_UA-xxxxxxxx"
            }}>{`_`}{`dc_gtm_UA-xxxxxxxx`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`app.uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/csrftoken"
            }}>{`csrftoken`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "performance-cookies"
    }}><a parentName="h3" {...{
        "href": "#performance-cookies"
      }}>{`Performance Cookies`}</a></h3>
    <p>{`These cookies allow us to count visits and traffic sources so we can measure
and improve the performance of our site. They help us to know which pages are
the most and least popular and see how visitors move around the site. All
information these cookies collect is aggregated and therefore anonymous. If you
do not allow these cookies we will not know when you have visited our site, and
will not be able to monitor its performance.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie Subgroup`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies used`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_ga"
            }}>{`_`}{`ga`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_gid"
            }}>{`_`}{`gid`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_ga_KVY361G7LD"
            }}>{`_`}{`ga_KVY361G7LD`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_gclxxxx"
            }}>{`_`}{`gclxxxx`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hp2_hld"
            }}>{`_`}{`hp2_hld`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__hssc"
            }}>{`_`}{`_`}{`hssc`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__hstc"
            }}>{`_`}{`_`}{`hstc`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__hssrc"
            }}>{`_`}{`_`}{`hssrc`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjid"
            }}>{`_`}{`hjid`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjTLDTest"
            }}>{`_`}{`hjTLDTest`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjFirstSeen"
            }}>{`_`}{`hjFirstSeen`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInPageviewSample"
            }}>{`_`}{`hjIncludedInPageviewSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjAbsoluteSessionInProgress"
            }}>{`_`}{`hjAbsoluteSessionInProgress`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInSessionSample"
            }}>{`_`}{`hjIncludedInSessionSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjSessionUser_1033711"
            }}>{`_`}{`hjSessionUser_1033711`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjSessionRejected"
            }}>{`_`}{`hjSessionRejected`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjSession_1033711"
            }}>{`_`}{`hjSession_1033711`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`app.uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInPageviewSample"
            }}>{`_`}{`hjIncludedInPageviewSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInSessionSample"
            }}>{`_`}{`hjIncludedInSessionSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjSessionRejected"
            }}>{`_`}{`hjSessionRejected`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cdnjs.cloudflare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInSessionSample"
            }}>{`_`}{`hjIncludedInSessionSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInPageviewSample"
            }}>{`_`}{`hjIncludedInPageviewSample`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`script.hotjar.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInSessionSample"
            }}>{`_`}{`hjIncludedInSessionSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjIncludedInPageviewSample"
            }}>{`_`}{`hjIncludedInPageviewSample`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hjSessionRejected"
            }}>{`_`}{`hjSessionRejected`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "functional-cookies"
    }}><a parentName="h3" {...{
        "href": "#functional-cookies"
      }}>{`Functional Cookies`}</a></h3>
    <p>{`These cookies enable the website to provide enhanced functionality and
personalisation. They may be set by us or by third party providers whose
services we have added to our pages. If you do not allow these cookies then
some or all of these services may not function properly.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie Subgroup`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies used`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/ajs_anonymous_id"
            }}>{`ajs_anonymous_id`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/ajs%3Atest"
            }}>{`ajs%3Atest`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/ajs%3Acookies"
            }}>{`ajs%3Acookies`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/%5B__tld__"
            }}><strong parentName="a">{`tld`}</strong></a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/hubspotutk"
            }}>{`hubspotutk`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`app.uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__stripe_sid"
            }}>{`_`}{`_`}{`stripe_sid`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__stripe_mid"
            }}>{`_`}{`_`}{`stripe_mid`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`help.uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/PLAY_SESSION"
            }}>{`PLAY_SESSION`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`github.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_gh_sess"
            }}>{`_`}{`gh_sess`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_octo"
            }}>{`_`}{`octo`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/logged_in"
            }}>{`logged_in`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`m.stripe.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/m"
            }}>{`m`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cdpn.io`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__cfruid"
            }}>{`_`}{`_`}{`cfruid`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`codepen.io`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__cfruid"
            }}>{`_`}{`_`}{`cfruid`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/cp_session"
            }}>{`cp_session`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/__cf_bm"
            }}>{`_`}{`_`}{`cf_bm`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "targeting-cookies"
    }}><a parentName="h3" {...{
        "href": "#targeting-cookies"
      }}>{`Targeting Cookies`}</a></h3>
    <p>{`These cookies may be set through our site by our advertising partners. They
may be used by those companies to build a profile of your interests and show
you relevant adverts on other sites. They do not store directly personal
information, but are based on uniquely identifying your browser and internet
device. If you do not allow these cookies, you will experience less targeted
advertising.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie Subgroup`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies used`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`uploadcare.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/ajs_user_id"
            }}>{`ajs_user_id`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hp2_id.##########"
            }}>{`_`}{`hp2_id.##########`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_hp2_ses_props.##########"
            }}>{`_`}{`hp2_ses_props.##########`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_fbp"
            }}>{`_`}{`fbp`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`google.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/_GRECAPTCHA"
            }}>{`_`}{`GRECAPTCHA`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`youtube.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/YSC"
            }}>{`YSC`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/VISITOR_INFO1_LIVE"
            }}>{`VISITOR_INFO1_LIVE`}</a>{` `}<a parentName="td" {...{
              "href": "https://cookiepedia.co.uk/cookies/CONSENT"
            }}>{`CONSENT`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third party`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      